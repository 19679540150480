import React, { useState } from 'react';
import Cross from 'figma/components/Icons/cross/Cross/Cross';
import './ReferralModalProgram.scss';
import { Button, Input, message } from 'antd';
import Copy from './Icons/Copy';
import { GET_REFERRAL_LINK } from './graphql';
import { useApolloClient } from 'react-apollo';
import { useTranslation } from 'react-i18next';

const ReferralModalProgram = ({ onCancel, props }) => {
  const [link, setLink] = useState('');
  const client = useApolloClient();
  const { t } = useTranslation();
  const getGenerateLink = async () => {
    try {
      const res = await client.query({
        query: GET_REFERRAL_LINK,
        fetchPolicy: 'no-cache',
        variables: {
          id: props.profile.id,
        },
      });
      const fetchedLink = res.data.referral.getReferralLink.link;
      const newLink = `${window.parametrize(
        'REACT_APP_SHOP_URL',
      )}/?ref=${fetchedLink}`;
      setLink(newLink);
      message.success(t('profile.referral_modal.success'));
    } catch (e) {
      console.error(e);
      message.error(t('profile.referral_modal.error'));
    }
  };
  const copyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      message.success(t('profile.referral_modal.copied'));
    });
  };

  const clickToggle = () => {
    if (link === '') getGenerateLink();
  };
  return (
    <div className="referral-modal">
      <div className="referral-modal__header">
        <div className="referral-modal__title">
          {t('profile.aside_referral.aside_title')}
        </div>
        <div className={'fancoinsModal__header__close'} onClick={onCancel}>
          <Cross className={'fancoinsModal__header__close__icon'} />
        </div>
      </div>
      <div className="referral-modal__content">
        <div className="referral-modal__description">
          <p className="referral-modal__instruction">
            {t('profile.referral_modal.instruction')}
          </p>
          <p className="referral-modal__details">
            {t('profile.referral_modal.details')}
          </p>
        </div>
        <div className="referral-modal__link-generator">
          <div className="input-with-copy-container">
            <Copy className="icon-copy" onClick={copyLink} />
            <Input
              type="text"
              className="referral-modal__input"
              placeholder={t('profile.referral_modal.placeholder')}
              value={link}
              readOnly
            />
          </div>
          <div className="placeholder__link" onClick={clickToggle}>
            {link == ''
              ? t('profile.referral_modal.generate')
              : t('profile.referral_modal.share')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralModalProgram;
