import gql from 'graphql-tag';

export const GET_REFERRAL_LINK = gql`
  query($id: ID!) {
    referral {
      getReferralLink(id: $id) {
        userId
        link
        createdAt
      }
    }
  }
`;
