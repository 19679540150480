import React from "react";
import { ReactComponent as CrossSvg } from "./cross.styled.svg";

function propsToCssVars(props?: CrossProps): React.CSSProperties {
  return {
    "--fan-theme_clubNavigation1": props?.styles?.fanThemeClubNavigation1
  } as React.CSSProperties;
}

export type CrossProps = {
  styles?: {
    fanThemeClubNavigation1?: string;
  };
};

/**
 * Generated component
 * Available CSS variables:
 * fan-theme_clubNavigation1,
 **/
export default function Cross(props?: CrossProps) {
  return <CrossSvg style={{ ...propsToCssVars(props) }} />;
}
